import React from 'react';
import logo from '../../images/chrome-storeIcon.png'; // with import
import './header.styles.scss'
import { company as c } from '../../config/main';

type Props = {};

const Header = (props: Props) => {

  return (
    <header id="dpHeader">
      <div className="store-icon-wrapper">
        <a href={c.link} target="_blank" id="storeIcon" data-buttonlocation="Top">
          <img src={logo} />
        </a>
      </div>
    </header>
  );
};

export default Header;
