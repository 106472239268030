import React from 'react';
import { company as c } from "../../config/main";
import screenshot from '../../images/pcImg.png';
import './home.styles.scss';

type Props = {};

const Home = (props: Props) => {
  return (
    <div id="mainContent" className="homepage">
      <div className="center-wrap">
        <h1 className="title">
          Over 100+ of the best nature wallpaper images with our extension!
        </h1>
        <div className="left-content">
          <h2 className="subtitle">Enjoy our wallpapers.</h2>
          <div className="btn">
            <a
              href={c.link}
              rel="noreferrer"
              className="js-install-chrome button cta"
              target="_blank"
              id="add_btn"
              data-buttonlocation="Center"
            >
              <span>Add to Chrome</span>
            </a>
          </div>
          <div className="disclaimer_txt">
            By clicking you accept and agree to install our extension, set the
            Chrome New Tab to The Extension Homepage, and to the Terms of Use
            and Privacy Policy. Please note that the extension will change your
            New Tab provider to Microsoft Bing.
          </div>
        </div>
        <div className="right-content">
          <img
            id="pcImg"
            className="animate-flicker"
            src={screenshot}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
